<style scoped>
.modalbox-content {
  margin: 10px 0;
}
</style>

<template>
<modal name="produtoEdit" height="auto" width="900" :lazy="true" :scrollable="true" :clickToClose="false">
  <CloseModal :close="close" />
  <div class="modalbox">
    <div class="xrow">
      <div class="col-1-1">
        <h2 class="text-bold text-gray-dark">
          {{item.id}} - {{item.descNF}}
        </h2>
      </div>
    </div>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Imagens">
        <Imagens
          :imagens="imagens"
          :item="item"
          :errorMessage="errorMessage"
          :remove="remove"
          :upload="upload"
        />
      </CTab>
      <CTab title="Similares">
        <Similares
          :items="items"
          :table="table"
          :similaresAdicionados="similaresAdicionados"
          :removeSimilar="removeSimilar"
          :jaAdicionado="jaAdicionado"
          :addSimilar="addSimilar"
          :loadProdutos="loadProdutos"
          :termCod="termCod"
          :termDesc="termDesc"
          :cdnUrl="cdnUrl"
        />
      </CTab>
      <CTab title="Arg. Venda">
        <ArgumentoVenda
          :item="item"
        />
      </CTab>
      <CTab title="Inf. Técnicas">
        <InformacoesTecnicas
          :item="item"
        />
      </CTab>
      <CTab title="Dados Técnicos">
        <DadosTecnicos
          :item="item"
        />
      </CTab>
    </CTabs>
  </div>
  <div class="modal-footer">
    <button type="button" class="button button-success" v-on:click="_onSave">Salvar</button>
    <button type="button" class="button" v-on:click="onClose">Fechar</button>
  </div>
</modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import _remove from "lodash/remove";
import _find from "lodash/find";
import * as cdn from "../../helpers/url";
import fs from "path";
import { baseName, makeHash } from "../../helpers/utils";
import { cdnUrlProduto } from "../../helpers/url";
import { post, get, del } from "@/helpers/apiRequest";
import CloseModal from "../../components/CloseModal";

import Imagens from "./Edit/Imagens";
import Similares from "./Edit/Similares";
import ArgumentoVenda from "./Edit/ArgumentoVenda";
import InformacoesTecnicas from "./Edit/InformacoesTecnicas";
import DadosTecnicos from "./Edit/DadosTecnicos";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

function removeExtenton(fileName){
  
  let name =  fileName;
  let indexExtention  = name.lastIndexOf('.');
  let sliceLength     = name.length - indexExtention;
  sliceLength = -sliceLength;
  
  return name.slice(0, sliceLength);
}

function getExtenton(fileName)
{
  return fileName.split('.').pop();
}

export default {
  name: "produtoEdit",
  components: {
    CloseModal,
    Imagens,
    Similares,
    ArgumentoVenda,
    InformacoesTecnicas,
    DadosTecnicos,
  },
  watch: {
    "item.id": function(id) {
      this.imagens = [];
      this.loadImages(id);
    },
  },
  props: ["onSave", "item", "close"],
  data() {
    return {
      activeTab: 1,
      errorMessage: null,
      imagens: [],
      termDesc: "",
      termCod: "",
      items: [],
      table: {
        filter: "",
        columns: [
          {
            label: "ID",
            field: "id",
            headerAlign: "left",
            sortable: false,
          },
          {
            label: "Imagem",
            field: "imagem",
            sortable: false,
          },
          {
            label: "Descrição",
            field: "descNF",
            headerAlign: "left",
            align: "left",
            sortable: false,
          },
          {
            label: "",
            field: "",
            sortable: false,
          },
        ],
        paginator: {},
      },
    };
  },
  validators: {},
  filters: {
    baseName(url) {
      return baseName(url);
    },
  },
  computed: {
    similaresAdicionados() {
      return this.item.similares;
    },
  },
  methods: {
    onClose() {
      this.clearData();
      this.close();
    },
    _onSave() {
      this.onSave();
      this.clearData();
    },
    loadImages(id) {
      fetch(`${cdn.cdnApi(id)}`)
        .then((res) => res.json())
        .then((res) => {
          const images = [];
          if (!!res.data) {
            res.data.all.map((path) => {
              images.push(cdn.cdnUrl(path));
            });
          }
          this.imagens = images;
        });
    },
    upload(event) {
      const id = this.item.id;
      const name = removeExtenton(event.target.files[0].name);
      const file = event.target.files[0];

      let formData = new FormData();
      formData.append("imagem", file);

      post(`/admin/cdn/produtos/${id}/${name}`, formData, { isFile: true })
        .then((res) => {
          event.target.value = "";
          this.loadImages(this.item.id);
          this.$swal({
            title: "Sucesso!",
            text: "Imagem enviada com sucesso ao repositório!",
            showCancelButton: false,
            cancelButtonText: "Fechar",
            showConfirmButton: true,
            icon: "success",
          });
        })
        .catch((error) => {
          event.target.value = "";
          this.$swal({
            title: "Ops!",
            text: error.message,
            showCancelButton: false,
            cancelButtonText: "Fechar",
            showConfirmButton: true,
            icon: "error",
          });
        });
    },
    remove(path) {
      const id        = this.item.id;
      const name      = removeExtenton( fs.basename(path));
      const extension = getExtenton(fs.basename(path));

      del(`/admin/cdn/produtos/${id}/${name}/${extension}`)
        .then((response) => response.status)
        .then((status) => this.loadImages(this.item.id));
    },
    clearData() {
      this.errorMessage = null;
      this.items = [];
      this.activeTab = 1;
      this.termCod = "";
      this.termDesc = "";
    },
    loadProdutos(page = 1) {
      this.currentPage = page;
      this.currentHash = makeHash();

      get(
        `/admin/produtos?page=${page}&filtros[produto_id]=${
          this.termCod
        }&filtros[termo]=${this.termDesc}`
      )
        .then((json) => {
          this.items = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    addSimilar(similar) {
      this.item.similares.push(similar);
    },
    removeSimilar(id) {
      const simAux = [...this.item.similares];
      _remove(simAux, (sim) => sim.id === id);
      this.item.similares = simAux;
    },
    jaAdicionado(id) {
      return !!_find(this.item.similares, (sim) => sim.id === id);
    },
    cdnUrl(id) {
      return cdnUrlProduto(id, this.currentHash);
    },
  },
  mounted() {
    this.clearData();
  },
};
</script>
